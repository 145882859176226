body,
#root {
	height: 100dvh;
	margin: 0;
}

body {
	@apply bg-base-200;
}

.card {
	@apply bg-base-100;
	@apply shadow;
}

.hide-scrollbar {
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
