.app-home-page {
	.image-render-container {
		height: calc(100% - 208px);
		position: relative;

		.image-combine {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			object-fit: contain;

			@apply h-full;
			@apply m-auto;
		}
	}
}
