.color-option {
	position: relative;
	height: 80px;

	@apply flex;
	@apply items-center;
	// @apply justify-center;
	@apply flex-col;

	.color-option-btn {
		top: 0px;
		border: none;
		outline: none;
		margin: 0px 11px;
		overflow: hidden;
		position: relative;
		width: 40px;
		height: 40px;
		background-color: transparent;
		border-radius: 100%;

		@apply flex;
		@apply items-center;
		@apply justify-center;
		@apply flex-col;

		&.isSelected {
			border: 1px solid rgb(229, 229, 229);
		}

		.color-option-btn-bg {
			width: 30px;
			height: 30px;
			border-radius: 100%;
			border: 1px solid rgb(229, 229, 229);
		}
	}

	.color-option-label {
		width: 120px;
		font-size: 12px;
		font-weight: 500;
		position: absolute;
		text-align: center;
		transform: translateY(45px);
	}
}
